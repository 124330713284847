@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-black text-white;
  }

  select {
    @apply rounded-md bg-white bg-opacity-10 py-2 px-4 border-none font-medium;
  }
  /* width */
  ::-webkit-scrollbar {
    @apply bg-transparent w-2 cursor-pointer;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply transition duration-500 ease-in-out bg-red-800 border-0 rounded-lg hover:bg-accent;
  }
  :root {
    --plyr-color-main: #d53527;
  }
}

@layer utilities {
  @screen lg {
    .plyr video,
    .plyr iframe,
    .plyr audio {
      height: 864px;
    }
  }
  select option:checked {
    background: #d53527 -webkit-linear-gradient(bottom, #d53527 0%, #d53527 100%);
  }
  select option:hover {
    background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
    color: #fff;
  }
  select option {
    background-color: #1a1a1a;
  }

  .input-dot:checked ~ .dot {
    transform: translateX(100%);
    background-color: #d53527;
  }

  .tag {
    @apply rounded-md px-4 py-1 border-none text-white bg-white bg-opacity-10 font-semibold text-sm flex items-center;
  }

  .NavbarItem {
    @apply rounded-full px-2.5 py-2.5 xl:px-8 xl:py-3.5 break-normal cursor-pointer;
  }

  .NavbarSelectedItem {
    @apply NavbarItem bg-accent;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.swiper-button-prev {
  @apply opacity-0 md:opacity-100 2xl:ml-16;
}
.swiper-button-next {
  @apply opacity-0 md:opacity-100 2xl:mr-16;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  @apply text-white text-opacity-80;
}
.swipper-navigation-disabled {
  @apply opacity-0 md:opacity-40;
}

.video-js .vjs-big-play-button {
  /* position: absolute;
  top: 50% !important;
  left: 48% !important; */
  @apply absolute !top-[40%] !left-[45%] md:!top-[50%] md:!left-[46%] !transition-opacity ease-in-out duration-500 !w-16 !h-16 !rounded-full !bg-white !bg-opacity-5 !opacity-80 !border-none;
}

.video-js .vjs-big-play-button:hover {
  @apply !opacity-100;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  @apply !flex !justify-center !items-center;
}

._ovIFV {
  border: 4px solid #ffffff;
  border-radius: 5px;
}

.text-danger {
  color: #dc3545;
}

/* Plyr */
#plyr,
.wrapper {
  width: 100%;
}

.plyr--full-ui input[type='range'] {
  color: #d53527;
}

.plyr__control--overlaid {
  background: rgba(213, 53, 39, 0.5);
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded='true'] {
  background: #d53527;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(213, 53, 39, 0.5);
}

.plyr__menu__container
  .plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background: #d53527;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 140px;
  background-color: #d53527;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Điều chỉnh vị trí */
  left: 50%;
  margin-left: -68px;
  opacity: 0;
  font-size: 12px;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
