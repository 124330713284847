@tailwind utilities;

@layer utilities {
  .butaca-static {
    @apply lg:px-20 xl:px-20;
  }

  .butaca-static h1,
  .butaca-static h2,
  .butaca-static h3 {
    @apply font-sans m-0 tracking-normal mt-8;
  }
  .butaca-static h1 {
    @apply text-4xl mb-8;
  }
  .butaca-static h2 {
    @apply text-2xl;
  }
  .butaca-static h3 {
    @apply text-xl;
  }
  .butaca-static ul {
    @apply list-disc ml-5 my-3;
  }

  .butaca-static ol {
    @apply list-decimal ml-5 my-3;
  }

  .butaca-static ol li {
    @apply pl-1;
  }

  .butaca-static ol li::marker {
    @apply italic;
  }

  .butaca-static video,
  .butaca-static img {
    @apply w-auto;
  }

  .butaca-static figure {
    @apply mx-auto;
  }
  .butaca-static figure.image {
    @apply flex flex-col items-center mt-12;
  }

  .butaca-static figure.image figcaption {
    @apply text-center py-4 text-sm italic md:w-1/2 mx-auto;
  }

  .butaca-static i {
    @apply italic;
  }

  .butaca-static strong {
    @apply font-bold;
  }

  .butaca-static a {
    @apply ml-1 font-semibold hover:underline;
  }

  .butaca-static hr {
    border-top-width: 2px;
  }

  .butaca-static blockquote {
    @apply my-8 italic border-l-4 pl-4;
  }

  .butaca-static blockquote h1,
  .butaca-static blockquote h2,
  .butaca-static blockquote h3 {
    @apply m-0;
  }

  .butaca-static blockquote h1 {
    @apply text-2xl;
  }

  .butaca-static blockquote h3 {
    @apply text-xl;
  }

  .butaca-static blockquote h3 {
    @apply text-lg;
  }

  .butaca-static p {
    @apply py-2;
  }
  .butaca-static p img {
    @apply inline-block w-max;
  }
}
